import styled from '@emotion/styled';

import Text from 'components/Text';

export const TooltipContainer = styled.div`
  background-color: ${(props) => props.theme.surface.secondary};
  border-radius: 10px;
  padding: 10px 16px;
  max-width: 360px;
  margin-left: 16px;
  border: 1px solid ${(props) => props.theme.surface.tertiary};
`;

export const TooltipText = styled(Text)`
  color: ${(props) => props.theme.foreground.primary};
  text-align: center;
  font-weight: 500;
`;

export const TooltipArrow = styled.div`
  display: flex;
`;
