import React from 'react';
import { css } from '@emotion/react';
import usePassiveLayoutEffect from '@react-hook/passive-layout-effect';

export const LoadingContext = React.createContext<(to: boolean) => void>(() => {});

const LoadingBoundary = React.memo(() => {
  const register = React.useContext(LoadingContext);

  usePassiveLayoutEffect(() => {
    register(false);
    return () => register(true);
  }, [register]);

  return (
    <div
      css={css`
        flex-grow: 1;
      `}
    />
  );
});

export default LoadingBoundary;
