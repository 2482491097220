import React from 'react';
import type { TippyProps } from '@tippyjs/react';
import dynamic from 'next/dynamic';

import * as Styled from './styles';

const Tippy = dynamic(() => import('@tippyjs/react/headless'), { ssr: true });

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode;
}

export const Tooltip = ({ children, ...props }: Props) => (
  <div tabIndex={-1} {...props}>
    <Styled.TooltipContainer>
      <Styled.TooltipText>{children}</Styled.TooltipText>
    </Styled.TooltipContainer>
  </div>
);

export default function LazyTippy(props: TippyProps) {
  const [mounted, setMounted] = React.useState(false);

  const lazyPlugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  };

  const computedProps = { ...props };

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])];

  if (props.render) {
    const render = props.render;
    computedProps.render = (...args) => (mounted ? render(...args) : '');
  } else {
    computedProps.content = mounted ? props.content : '';
  }

  return <Tippy {...computedProps} />;
}
