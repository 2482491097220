import React, { ComponentPropsWithoutRef } from 'react';

import Tick from 'assets/tick.svg';
import Warn from 'assets/warn.svg';
import * as Styled from './styles';

export interface StyledInputProps {
  icon?: any;
  iconAlt?: string;
  hideIcons?: boolean;
  error?: boolean;
}

interface Props extends StyledInputProps, ComponentPropsWithoutRef<'input'> {
  innerRef?: React.ForwardedRef<HTMLInputElement>;
  as?: 'input' | 'textarea';
}

const StyledTextInput = React.memo(
  ({ icon, iconAlt, innerRef, children, hideIcons, error, required, ...props }: Props) => {
    const hasIcon = !!(icon && iconAlt);

    return (
      <Styled.Wrapper data-disabled={props.disabled}>
        <Styled.Input
          data-left-icon={hasIcon}
          ref={innerRef}
          {...props}
          data-invalid={error}
          aria-required={required}
          required={required}
        />
        {hasIcon && <Styled.InputIcon src={icon} size={24} alt={iconAlt} />}
        {!hideIcons && (
          <>
            <Styled.StatusIcon className="tick" src={Tick} size={24} alt="Valid input icon" />
            {error && <Styled.ErrorIcon className="warn" src={Warn} size={24} alt="Invalid input icon" />}
          </>
        )}
        {children}
      </Styled.Wrapper>
    );
  }
);

export default React.forwardRef<HTMLInputElement, Props>((props, ref) => <StyledTextInput {...props} innerRef={ref} />);
