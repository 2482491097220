import React, { ComponentPropsWithoutRef } from 'react';
import { css } from '@emotion/react';
import { FieldError as IFieldError } from 'react-hook-form';

import FieldError from 'components/FieldError';
import Label from 'components/Label';
import StyledTextInput, { StyledInputProps } from 'components/StyledTextInput';

import * as Styled from './styles';

export interface Props extends ComponentPropsWithoutRef<'input'>, StyledInputProps {
  optional?: boolean;
  label?: string;
  error?: IFieldError;
  innerRef?: React.ForwardedRef<HTMLInputElement>;
  as?: 'input' | 'textarea';
  children?: React.ReactNode;
  tip?: React.ReactNode;
}

const textAreaStyle = css`
  min-height: 204px;
  resize: vertical;
`;

const TextInput = React.memo(
  ({ name, label, placeholder, className, style, error, optional, innerRef, children, as, tip, required, ...props }: Props) => {
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    React.useEffect(() => {
      if (inputRef.current) {
        inputRef.current.setCustomValidity(error?.message || '');
      }
    }, [error]);

    return (
      <Styled.Wrapper className={className} style={style}>
        {label && (
          <Label optional={optional} htmlFor={name}>
            {label}
            {tip}
          </Label>
        )}
        {children}
        <Styled.InputWrapper>
          <StyledTextInput
            {...props}
            id={name}
            name={name}
            placeholder={placeholder}
            error={!!error}
            aria-invalid={!!error}
            ref={(ref) => {
              inputRef.current = ref;

              if (typeof innerRef === 'function') {
                innerRef(ref);
              } else if (innerRef) {
                innerRef.current = ref;
              }
            }}
            as={as}
            css={as === 'textarea' && textAreaStyle}
            required={required}
          />
        </Styled.InputWrapper>
        <FieldError error={error} />
      </Styled.Wrapper>
    );
  }
);

export default React.forwardRef<HTMLInputElement, Props>((props, ref) => <TextInput innerRef={ref} {...props} />);
