import styled from '@emotion/styled';

import typography from 'styles/typography';

export interface StyledInputProps {
  'data-left-icon'?: boolean;
  'data-right-icon'?: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
  background-color: ${(props) => props.theme.surface.primary};
  color: ${(props) => props.theme.ui.dark};
  border: 2px solid ${(props) => props.theme.foreground.quaternary};
  border-radius: 4px;
  padding: 10px 12px;
  flex: 1;
  ${typography.body2}
  appearance: none;

  &[data-left-icon='true'] {
    padding-left: 50px;
  }

  &[data-right-icon='true'] {
    padding-right: 50px;
  }

  &[data-invalid='true'] {
    border-color: ${(props) => props.theme.status.error};
  }

  &:focus {
    border-color: ${(props) => props.theme.ui.dark};
    outline: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.foreground.primary};
  }

  &:not(:placeholder-shown):valid {
    border-color: ${(props) => props.theme.ui.dark};
  }

  &:disabled {
    background-color: ${(props) => props.theme.surface.secondary};
    border-color: ${(props) => props.theme.surface.tertiary};
    color: ${(props) => props.theme.foreground.quaternary};
    cursor: not-allowed;

    &::placeholder {
      color: ${(props) => props.theme.foreground.quaternary};
    }
  }
`;

export default StyledInput;
