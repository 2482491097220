import React from 'react';
import usePassiveLayoutEffect from '@react-hook/passive-layout-effect';
import Head from 'next/head';
import Router from 'next/router';
import { NextSeo } from 'next-seo';
import nProgress from 'nprogress';

import { LoadingContext } from 'components/Loading';
import useUser, { USER_PREFETCH_URL } from 'data/useUser';
import decorateStatics from 'lib/hoc/decorateStatics';
import { NextStaticPageConfig, NextWrappedPage } from 'types';
import { AuthRole } from 'types/enums';

import getRedirect from './redirect';

const isAnonymousRole = (role: AuthRole) => [AuthRole.Anonymous, AuthRole.All].includes(role);

const staticPage =
  <P extends object, IP = {}>(config: NextStaticPageConfig = {}) =>
  (Component: NextWrappedPage<P, IP>) => {
    const role = config.role || AuthRole.All;

    const WrappedComponent: React.FC<P> = (props) => {
      const validatedUser = React.useRef(false);
      const [loaded, setLoaded] = React.useState(true);
      const loadedRef = React.useRef(true);

      const { user, isValidating } = useUser();

      usePassiveLayoutEffect(() => {
        if (validatedUser.current) {
          return;
        }

        if (!isValidating) {
          const redirectTo = getRedirect({
            role,
            user,
          });

          if (redirectTo) {
            Router.replace(redirectTo);
          }
        }

        /**
         * This prevents redirect logic from emitting twice.
         */
        if (!isValidating) {
          validatedUser.current = true;
        }
      }, [user, isValidating]);

      React.useEffect(() => {
        if (!nProgress.isStarted() && ((!user && !isAnonymousRole(role)) || !loaded)) {
          nProgress.start();
        }
      }, [user]);

      React.useEffect(() => {
        if (
          user &&
          ((process.env.NODE_ENV === 'development' && nProgress.isStarted()) || process.env.NODE_ENV === 'production')
        ) {
          if (loadedRef.current) {
            nProgress.done();
          } else {
            nProgress.inc(0.5);
          }
        }

        if (!user && isAnonymousRole(role)) {
          if (loadedRef.current) {
            nProgress.done();
          } else {
            nProgress.inc(0.5);
          }
        }
      });

      const setLoadedMemo = React.useCallback(
        (to: boolean) => {
          setLoaded(to);
          loadedRef.current = to;
        },
        [setLoaded]
      );

      return (
        <>
          <Head>
            {!isAnonymousRole(role) && (
              <link
                rel="preload"
                href={USER_PREFETCH_URL}
                as="fetch"
                crossOrigin="use-credentials"
                type="application/json"
              />
            )}
          </Head>
          {config.title && <NextSeo title={config.title} />}
          <LoadingContext.Provider value={setLoadedMemo}>
            {user || [AuthRole.Anonymous, AuthRole.All].includes(role) ? (
              <Component {...props} />
            ) : (
              <div style={{ flex: '1 0 auto' }} />
            )}
          </LoadingContext.Provider>
        </>
      );
    };

    return decorateStatics<P, IP>(config)(WrappedComponent);
  };

export default staticPage;
