import React from 'react';
import styled from '@emotion/styled';
import { FieldError as IFieldError } from 'react-hook-form';

import { maxLength, minLength, required } from 'lib/forms';
import theme from 'styles/theme';
import typography from 'styles/typography';

const StyledFieldError = styled.span([
  typography.body2,
  {
    color: theme.status.error,
    marginTop: '2px',
  },
]);

interface Props {
  error?: IFieldError;
}

const renderedError = ['required', 'minLength', 'maxLength'];

const FieldError = ({ error }: Props) => {
  if (!error) {
    return null;
  }

  return (
    <>
      {error.type === 'required' && <StyledFieldError role="alert">{error.message || required}</StyledFieldError>}
      {error.type === 'minLength' && <StyledFieldError role="alert">{minLength}</StyledFieldError>}
      {error.type === 'maxLength' && <StyledFieldError role="alert">{maxLength}</StyledFieldError>}
      {!renderedError.includes(error.type) && error.message && (
        <StyledFieldError role="alert">{error.message}</StyledFieldError>
      )}
    </>
  );
};

export default React.memo(FieldError);
