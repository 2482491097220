import React, { ComponentPropsWithoutRef } from 'react';
import styled from '@emotion/styled';

import LazyTippy, { Tooltip } from 'components/LazyTippy';
import Text from 'components/Text';

const StyledLabel = styled(Text.withComponent('label'))`
  margin-bottom: 8px;
  color: ${(props) => props.theme.foreground.primary};
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TooltipLabel = styled(Text)`
  text-decoration: underline;
  color: ${(props) => props.theme.foreground.primary};
  font-weight: 500;
  cursor: help;
`;

interface Props extends ComponentPropsWithoutRef<'label'> {
  optional?: boolean;
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
  tip?: React.ReactNode;
}

const Label = ({ optional, tip, children, ...props }: Props) => (
  <LabelWrapper>
    <StyledLabel {...props} variant="label">
      {children}
      {optional && ' (Optional)'}
    </StyledLabel>
    {tip && (
      <LazyTippy render={(props) => <Tooltip {...props}>{tip}</Tooltip>} placement="auto-end">
        <TooltipLabel variant="body2">Why do we ask this?</TooltipLabel>
      </LazyTippy>
    )}
  </LabelWrapper>
);

export default React.memo(Label);
