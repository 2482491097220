import React from 'react';
import { get, Path, useForm as useReactHookForm, UseFormProps, UseFormReturn } from 'react-hook-form';

import { FIELD_ERROR, FORM_ERROR } from 'lib/forms';
import { FormError } from 'types';

import useAlert from './useAlert';

export default function useForm<FormValues extends FormError, TContext extends object = object>(
  config?: UseFormProps<FormValues, TContext>
): UseFormReturn<FormValues, TContext> {
  const form = useReactHookForm<FormValues, TContext>(config);
  const {
    formState: { errors },
    clearErrors,
  } = form;
  const { onFailure } = useAlert();

  const formError = get(errors, FORM_ERROR);

  React.useEffect(() => {
    if (formError && formError.type === FIELD_ERROR && formError.message) {
      onFailure(formError.message);
      clearErrors(FORM_ERROR as Path<FormValues>);
    }
  }, [formError, clearErrors, onFailure]);

  return form;
}
