import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Icon from 'components/Icon';
import StyledInput from 'components/StyledInput';

export const Wrapper = styled.div`
  position: relative;
  display: flex;

  &[data-disabled='true'] {
    & > div {
      opacity: 0.5;
    }
  }
`;

export const Input = styled(StyledInput)`
  &:not(:placeholder-shown):valid ~ .tick,
  &:not(:placeholder-shown):disabled ~ .tick {
    display: block;
  }
`;

const shared = css`
  position: absolute;
  top: 14px;
  pointer-events: none;
`;

export const InputIcon = styled(Icon)`
  ${shared}
  left: 14px;
`;

export const StatusIcon = styled(Icon)`
  ${shared}
  display: none;
  right: 14px;
`;

export const ErrorIcon = styled(Icon)`
  ${shared}
  right: 14px;
`;
