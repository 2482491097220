import { ComponentStatics, NextStaticPageConfig, NextWrappedPage } from 'types';

export default function decorateStatics<P extends object = {}, IP = P>(
  config: Pick<NextStaticPageConfig, 'header' | 'footer'> = {}
) {
  const showHeader = config.header ?? true;
  const showFooter = config.footer ?? true;

  return (WrappedComponent: NextWrappedPage<P, IP> & ComponentStatics) => {
    if (!showHeader) {
      WrappedComponent.hideHeader = true;
    }

    if (!showFooter) {
      WrappedComponent.hideFooter = true;
    }

    return WrappedComponent;
  };
}
