import { User } from 'types';
import { AuthRole, OnboardingStep } from 'types/enums';

interface Params {
  role: AuthRole;
  user?: User | null;
}

export default function getRedirect({ role, user }: Params) {
  if (role === AuthRole.All) {
    return undefined;
  }
  if (AuthRole.Anonymous !== role && !user) {
    return '/signin';
  }
  if (user) {
    if (role === AuthRole.Anonymous) {
      return '/';
    }
    if (role > AuthRole.Authenticated && !user.activated) {
      return '/set-up/verify-email';
    }
    if (role >= AuthRole.Onboarded && user.onboardingStep < OnboardingStep.Finished) {
      if (!user.onboardingStep || user.onboardingStep === OnboardingStep.First) {
        return '/set-up/survey/start';
      }
      if (user.onboardingStep === OnboardingStep.Second) {
        return '/set-up/survey/step-2';
      }
      if (user.onboardingStep === OnboardingStep.Third) {
        return '/set-up/survey/step-3';
      }
      if (user.onboardingStep === OnboardingStep.Fourth) {
        return '/set-up/survey/step-4';
      }
    }
  }
}
